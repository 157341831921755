import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableCellWhiteSpace,
  TableDots,
  Pagination,
  Chip,
  Button,
  enqueueSnackbar,
  gateModal,
} from '../../design-system';
import { DashboardHeader } from '../dashboard';
import { Filters } from './Filters';
import { useStore } from '../../context';
import { formatContainerType, momentTextFormat, showToRoles } from '../../utils';
import { isShowDots } from './utils';
import {
  useStatesWithFilterCounter,
  useSort,
  usePaginationState,
  useGateOutsQuery,
  useCompleteGateOutMutation,
} from '../../hooks';
import {
  gateTableDotsActions,
  TABLE,
  MESSAGES,
  GateStatusToChipLabels,
  GateStatusToChipColors,
} from '../../const';

interface GateOutsPageProps {
  innerScroll?: boolean;
}

export const GateOutsPage = styled.div<GateOutsPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const GateOutsHeader = ({ totalCount }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Gate Outs: {totalCount}
      </Typography>
      <Button size={'medium'} to={'/dashboard/gateouts/add'}>
        Create Request
      </Button>
    </DashboardHeader>
  );
};

export const GateOutsBody = ({ setTotalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const completeMutation = useCompleteGateOutMutation();
  const [openDotsMenuIndex, setDotsMenuIndex] = useState(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter(
    {
      containerNumber: '',
      type: 'All',
      containerType: 'All',
      yardCompanyId: '',
      country: '',
      city: '',
      yardId: '',
      clientId: '',
      createdAt: null,
      startDate: null,
      endDate: null,
      completedAt: null,
      driverName: '',
      licensePlateNumber: '',
      status: 'All',
    },
    { status: 'ACTIVE' },
  );
  const { sortField, sortDirection, handleSort } = useSort({
    sortField: filteredStates.status === 'COMPLETED' ? 'completedAt' : 'createdAt',
    sortDirection: 'desc',
  });

  const {
    data = { data: [], totalCount: 0 },
    isFetching,
    refetch,
  } = useGateOutsQuery({
    ...filteredStates,
    take,
    skip,
    sortField,
    sortDirection,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setDotsMenuIndex(i);

    setDotsMenuIndex(null);
  };

  const handleDotsOptionSelect = async (id: any, val: any, i: any) => {
    Object.keys(gateModal).forEach((key) => {
      if (key !== 'open') delete gateModal[key];
    });

    gateModal.val = val;
    gateModal.containerNumber = data.data[i].number;

    if (val === 'complete') {
      gateModal.type = 'GATE_OUT';
      gateModal.containerType = data.data[i].type;
      gateModal.licensePlateNumber = data.data[i].gateOut.authorizedLicensePlateNumbers[0];
      gateModal.authorizedLicensePlateNumbers = data.data[
        i
      ].gateOut.authorizedLicensePlateNumbers.map((val: any) => ({ label: val, value: val }));
      gateModal.comment = data.data[i].gateOut.comment;

      gateModal.fc = () =>
        completeMutation.mutate(id, {
          onSuccess: () => {
            enqueueSnackbar(MESSAGES.GATE_OUT.SUCCESS.COMPLETED, 'success');
            refetch();
          },
          onError: () => enqueueSnackbar(MESSAGES.GATE_OUT.ERROR.NOT_COMPLETED, 'error'),
        });
    }

    gateModal.open();
  };

  const handleRowClick = (to: string) => {
    if (openDotsMenuIndex !== null) return;
    navigate(to);
  };

  return (
    <TableWrapper columns={showToRoles('admin', user) ? [7, 10] : [20]}>
      <Filters
        handleSort={handleSort}
        setStates={setStates}
        withoutActions={!!showToRoles('client', user)}
        {...states}
      />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder={true}
        stickyHeader
        renderTableHeadRow={
          <TableRow>
            {showToRoles('admin', user) && <TableHeadCell align={'left'}>Actions</TableHeadCell>}
            {showToRoles('admin', user) && <TableHeadCell align={'left'}>Client</TableHeadCell>}
            <TableHeadCell align={'left'}>Container number</TableHeadCell>
            <TableHeadCell align={'left'}>Container type</TableHeadCell>
            <TableHeadCell align={'left'}>Status</TableHeadCell>
            <TableHeadCell
              align={'left'}
              columnIdx={'createdAt'}
              sortField={sortField}
              sortDirection={sortDirection}
              handleSort={handleSort}
            >
              Created at
            </TableHeadCell>
            {(states.status === 'All' || states.status === 'COMPLETED') && (
              <TableHeadCell
                align={'left'}
                columnIdx={'completedAt'}
                sortField={sortField}
                sortDirection={sortDirection}
                handleSort={handleSort}
              >
                Completed at
              </TableHeadCell>
            )}
            <TableHeadCell align={'left'}>Gate-Out Date From</TableHeadCell>
            <TableHeadCell align={'left'}>Gate-Out Date To</TableHeadCell>
            {states.status !== 'ACTIVE' && (
              <TableHeadCell align={'left'}>License Plate Number</TableHeadCell>
            )}
          </TableRow>
        }
        renderTableBodyRows={data.data.map((container, i) => (
          <TableRow
            key={container.number + i}
            onClick={() => handleRowClick(`/dashboard/gateouts/${container.id}`)}
          >
            {showToRoles('admin', user) ? (
              isShowDots(container.gateOut, user) ? (
                <TableIconCell component={'th'} align={'left'}>
                  <TableDots
                    open={openDotsMenuIndex === i}
                    onToggle={() => handleDotsToggle(i)}
                    options={gateTableDotsActions}
                    onSelect={(val) => {
                      handleDotsOptionSelect(container.id, val, i);
                    }}
                  />
                </TableIconCell>
              ) : (
                <TableCell component={'th'} align={'left'} />
              )
            ) : null}
            {showToRoles('admin', user) && (
              <TableCell align={'left'}>{container.clientYard?.client.code}</TableCell>
            )}
            {showToRoles('admin', user) && <TableCell align={'left'}>{container.number}</TableCell>}
            {showToRoles('client', user) && (
              <TableCell component={'th'} align={'left'}>
                {container.number}
              </TableCell>
            )}
            <TableCellWhiteSpace align={'left'}>
              {formatContainerType(container.type)}
            </TableCellWhiteSpace>
            <TableCell align={'left'}>
              <Chip
                size={'small'}
                label={GateStatusToChipLabels[container.gateOut.status]}
                color={GateStatusToChipColors[container.gateOut.status]}
              />
            </TableCell>
            <TableCell align={'left'}>
              {container.gateOut.createdAt && momentTextFormat(container.gateOut.createdAt, user)}
            </TableCell>
            {(states.status === 'All' || states.status === 'COMPLETED') && (
              <TableCell align={'left'}>
                {container.gateOut.completedAt &&
                  momentTextFormat(container.gateOut.completedAt, user)}
              </TableCell>
            )}
            <TableCell align={'left'}>
              {container.gateOut.validFrom && momentTextFormat(container.gateOut.validFrom, user)}
              {(states.status === 'All' || states.status === 'COMPLETED') &&
                !container.gateOut.validFrom &&
                momentTextFormat(container.gateOut.createdAt, user)}
            </TableCell>
            <TableCell align={'left'}>
              {container.gateOut.validTo && momentTextFormat(container.gateOut.validTo, user)}{' '}
              {(states.status === 'All' || states.status === 'COMPLETED') &&
                !container.gateOut.validTo &&
                momentTextFormat(container.gateOut.completedAt, user)}
            </TableCell>
            {states.status !== 'ACTIVE' && (
              <TableCell align={'left'}>{container.gateOut.driver?.licensePlateNumber}</TableCell>
            )}
          </TableRow>
        ))}
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
