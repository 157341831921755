import { useEffect, useState } from 'react';
import { Typography } from '../typography';
import { Button } from '../button';
import { Input, InputNumber, InputPrice } from '../input';
import { BorderLine } from '../border-line';
import {
  ModalBase,
  ModalWrapper,
  ModalFooterButtons,
  ModalBodyCustomColumns,
} from './ModalComponents';
import { enqueueSnackbar } from '../snackbar';
import { useStore } from '../../context';
import { useStoragePricingForm, useUpdatePricingMutation } from '../../hooks';
import { formatContainerType, momentTextFormat } from '../../utils';
import { saveCache, getCache } from '../../services';
import {
  containerTypes,
  INPUT_ERRORS,
  MESSAGES,
  pricingDetails,
  pricingFormLabels,
  StoragePricing,
} from '../../const';
import { ContainerType, StoragePricingUpdateModalCache } from '../../types';

export const StoragePricingUpdateModal = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { mutate } = useUpdatePricingMutation();

  const [showPricingFormModal, setShowPricingFormModal] = useState(false);
  const [yardName, setYardName] = useState('');
  const [clientName, setClientName] = useState('');
  const [{ effectiveDate, freeDays, ...containers }, setPricingState] = useStoragePricingForm({
    effectiveDate: '',
  });
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const validators = {
    freeDays: String(freeDays).length,
    loaded: containerTypes
      .map((containerType) => containers[containerType.value].loaded)
      .every((val: number) => String(val).length !== 0),
    empty: containerTypes
      .map((containerType) => containers[containerType.value].empty)
      .every((val: number) => String(val).length !== 0),
    loadedCrane: containerTypes
      .map((containerType) => containers[containerType.value].loadedCrane)
      .every((val: number) => String(val).length !== 0),
    emptyCrane: containerTypes
      .map((containerType) => containers[containerType.value].emptyCrane)
      .every((val: number) => String(val).length !== 0),
  };

  useEffect(() => {
    const open = () => {
      const cachedData = getCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal');

      const { containers, ...pricing } = cachedData.activePricing;
      setYardName(cachedData.clientYard?.yard.name);
      setClientName(cachedData.clientYard?.client.code);
      setPricingState({
        effectiveDate: momentTextFormat(pricing.activationDate, user),
        freeDays: pricing.freeDays,
        ...containers.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.type]: {
              loaded: curr.loaded,
              empty: curr.empty,
              loadedCrane: curr.loadedCrane,
              emptyCrane: curr.emptyCrane,
            },
          }),
          {},
        ),
      } as StoragePricing);

      setIsSubmitClicked(false);
      setShowPricingFormModal(true);
    };

    saveCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal', (props) => ({
      ...props,
      open,
    }));
  }, []);

  const handleOnClose = () => {
    const openModalFc = getCache<StoragePricingUpdateModalCache>(
      'storage-pricing-update-modal',
    ).open;
    saveCache('storage-pricing-update-modal', { open: openModalFc });
    setShowPricingFormModal(!open);
  };

  const handleSubmitClick = async () => {
    setIsSubmitClicked(true);
    if ([...Object.values(validators)].some((val) => !val)) {
      return;
    }

    const formProps = {
      id: getCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal').id,
      activePricingId: getCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal')
        .activePricing.id,
      freeDays,
      ...containers,
    };

    mutate(formProps, {
      onSuccess: () => {
        enqueueSnackbar(MESSAGES.STORAGE_PRICING.SUCCESS.UPDATED, 'success');
        setShowPricingFormModal(false);
        getCache<StoragePricingUpdateModalCache>('storage-pricing-update-modal').refetch();
      },
    });
  };

  return (
    <ModalBase
      id={'storage-pricing-update-modal'}
      open={showPricingFormModal}
      onClose={handleOnClose}
      paperSize={'big'}
    >
      <ModalWrapper>
        <Typography variant={'textL'} weight={'bold'}>
          Are you sure to Update Pricing?
        </Typography>
        {showPricingFormModal && (
          <>
            <ModalBodyCustomColumns columns={'1fr 1fr 1fr 1fr'}>
              <Input label={'Client'} data-size={'medium'} defaultValue={clientName} readOnly />
              <Input label={'Yard'} data-size={'medium'} defaultValue={yardName} readOnly />
              <Input
                label={'Effective Date'}
                data-size={'medium'}
                defaultValue={effectiveDate}
                readOnly
              />
              <InputNumber
                label={'Free Days'}
                data-size={'medium'}
                defaultValue={freeDays}
                onChange={(e) =>
                  setPricingState((props) => {
                    props.freeDays = Number(e.target.value);
                  })
                }
                error={isSubmitClicked ? !String(freeDays).length : false}
                helperText={
                  isSubmitClicked && !String(freeDays).length ? INPUT_ERRORS.REQUIRED.FREE_DAYS : ''
                }
              />
            </ModalBodyCustomColumns>
            <BorderLine space={'dashboard-form'} />
            {Object.keys(containers).map((containerType, i) => (
              <ModalBodyCustomColumns key={`container-${i}`} columns={'1fr 1fr 1fr 1fr'}>
                {pricingDetails.map((detail, n) => (
                  <InputPrice
                    key={`detail-${n}`}
                    label={pricingFormLabels[detail](
                      formatContainerType(containerType as ContainerType),
                    )}
                    data-size={'medium'}
                    defaultValue={containers[containerType as ContainerType][detail]}
                    onChange={(e) => {
                      setPricingState((props) => {
                        props[containerType as ContainerType][detail] = Number(e.target.value);
                      });
                    }}
                    error={
                      isSubmitClicked
                        ? !String(containers[containerType as ContainerType]).length
                        : false
                    }
                    helperText={
                      isSubmitClicked
                        ? !String(containers[containerType as ContainerType]).length
                          ? INPUT_ERRORS.REQUIRED.PRICE
                          : ''
                        : ''
                    }
                  />
                ))}
              </ModalBodyCustomColumns>
            ))}
          </>
        )}
        <ModalFooterButtons>
          <Button size={'medium'} data-color={'white'} onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size={'medium'} data-color={'primary'} onClick={handleSubmitClick}>
            Update
          </Button>
        </ModalFooterButtons>
      </ModalWrapper>
    </ModalBase>
  );
};
