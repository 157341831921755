import { AttachMoneyRounded as AttachMoneyRoundedIcon } from '@mui/icons-material';
import { Input, InputBaseProps } from './Input';

const allowedKeys = /[^0-9.]|(?<=\..*)\./g;

export const InputPrice: React.FC<InputBaseProps> = ({ onInput, ...props }: InputBaseProps) => {
  const handleOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(allowedKeys, '');

    if (onInput) onInput(e);
  };

  return <Input onInput={handleOnInput} endIcon={<AttachMoneyRoundedIcon />} {...props} />;
};
