import { useState } from 'react';
import {
  StoragePricingsPage,
  StoragePricingsHeader,
  StoragePricingsBody,
} from './StoragePricingsComponents';

export const StoragePricings = () => {
  const [totalCount, setTotalCount] = useState(0);

  return (
    <StoragePricingsPage innerScroll>
      <StoragePricingsHeader totalCount={totalCount} />
      <StoragePricingsBody setTotalCount={setTotalCount} />
    </StoragePricingsPage>
  );
};
