import { useMutation } from 'react-query';
import { toIsoDate } from '../../../utils';
import { storagePricingsService, removeCache } from '../../../services';
import {
  StoragePricingAddMutationProps,
  StoragePricingAddMutationRequest,
  StoragePricingUpdateMutationProps,
  StoragePricingUpdateMutationRequest,
} from '../../../types';
import { containerTypes } from '../../../const';

export const useAddPricingMutation = () => {
  return useMutation(
    (props: StoragePricingAddMutationProps) => {
      const requestProps: StoragePricingAddMutationRequest = {
        id: props.id,
        activationDate: toIsoDate(
          props.effectiveDate.set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        ),
        freeDays: Number(props.freeDays),
        containers: containerTypes.map((containerType) => ({
          type: containerType.value,
          loaded: props[containerType.value].loaded,
          empty: props[containerType.value].empty,
          loadedCrane: props[containerType.value].loadedCrane,
          emptyCrane: props[containerType.value].emptyCrane,
        })),
      };

      return storagePricingsService.addPricing(requestProps);
    },
    {
      onSuccess: () => {
        removeCache(['storage-pricing', 'key']);
      },
    },
  );
};

export const useUpdatePricingMutation = () => {
  return useMutation(
    (props: StoragePricingUpdateMutationProps) => {
      const requestProps: StoragePricingUpdateMutationRequest = {
        id: props.id,
        activePricingId: props.activePricingId,
        freeDays: Number(props.freeDays),
        containers: containerTypes.map((containerType) => ({
          type: containerType.value,
          loaded: props[containerType.value].loaded,
          empty: props[containerType.value].empty,
          loadedCrane: props[containerType.value].loadedCrane,
          emptyCrane: props[containerType.value].emptyCrane,
        })),
      };

      return storagePricingsService.updatePricing(requestProps);
    },
    {
      onSuccess: () => {
        removeCache(['storage-pricing', 'key']);
      },
    },
  );
};
