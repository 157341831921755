import { Navigate } from 'react-router-dom';
import { DashboardFormWrapper } from '../dashboard';
import { StoragePricingsPage } from './StoragePricingsComponents';
import { StoragePricingsAddHeader, StoragePricingsAddForm } from './StoragePricingsAddComponents';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';

export const StoragePricingsAdd = () => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  if (showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER', 'CLIENT_MANAGER', 'CLIENT_DISPATCHER'], user))
    return <Navigate to={'/dashboard/storage-pricing'} replace />;

  return (
    <StoragePricingsPage>
      <StoragePricingsAddHeader />
      <DashboardFormWrapper>
        <StoragePricingsAddForm />
      </DashboardFormWrapper>
    </StoragePricingsPage>
  );
};
