export const containerTypesLabel = {
  '20DC': "20'DC",
  '20HC': "20'HC",
  '40DC': "40'DC",
  '40HC': "40'HC",
  '45HC': "45'HC",
};

export const formatContainerType = (containerType: keyof typeof containerTypesLabel) => {
  if (!containerType) {
    return '';
  }

  return containerTypesLabel[containerType];
};
