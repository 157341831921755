import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { StoragePricingsPage as StoragePricingPageBase } from './StoragePricingsComponents';
import { StoragePricingsViewHeader, StoragePricingsTable } from './StoragePricingsViewComponents';
import { BorderLine, StoragePricingUpdateModal } from '../../design-system';
import { useStore } from '../../context';
import { useStoragePricingQuery } from '../../hooks';

const StoragePricingsPage = styled(StoragePricingPageBase)`
  grid-template-rows: initial;
  grid-auto-rows: max-content;
`;

export const StoragePricingsView = () => {
  const params = useParams();
  const { data, isFetching, refetch } = useStoragePricingQuery(params.id, 'desc');
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  if (isFetching) return <></>;
  if (!data) return <Navigate to={'/dashboard/storage-pricing'} replace />;

  return (
    <>
      <StoragePricingsPage>
        {data.pricing.map((pricing, i: number) => (
          <React.Fragment key={'storage-pricing-table' + i}>
            {i !== 0 ? <BorderLine space={'none'} /> : null}
            <StoragePricingsViewHeader isFirstIndex={i === 0} />
            <StoragePricingsTable data={data} pricing={pricing} user={user} refetch={refetch} />
          </React.Fragment>
        ))}
      </StoragePricingsPage>
      <StoragePricingUpdateModal />
    </>
  );
};
