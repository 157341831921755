import { useState, useRef } from 'react';
import {
  Typography,
  InfoItem,
  Button,
  FileRow,
  ImageGallery,
  ImageGalleryCard,
  BorderLine,
  EmptyFilesMessage,
  enqueueSnackbar,
  fullScreenButtonClick,
  HideInput,
  FileInput,
} from '../../design-system';
import {
  DashboardFormWrapper,
  DashboardHeader,
  DashboardFormCards,
  DashboardFormThreeCards,
  DashboardFormCard,
  DashboardFormCustomColumn,
  DashboardFooterWithResize,
} from '../dashboard';
import { useStore } from '../../context';
import {
  useStatesWithFilterCounter,
  useUpdateImagesMutation,
  useUpdateDocumentsMutation,
} from '../../hooks';
import {
  showToRoles,
  momentTextFormat,
  filesFormatValidator,
  getFilesChanges,
  formatPhoneNumber,
  formatContainerType,
} from '../../utils';
import { containersService } from '../../services';
import { containerStatusLabel, MESSAGES } from '../../const';

export const ContainersViewHeader = ({ container }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        {container.number}
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
    </DashboardHeader>
  );
};

export const ContainerFormCards = ({ children }: any) => {
  return (
    <DashboardFormWrapper flow={'row'}>
      <DashboardFormCards id={'containers-view'}>{children}</DashboardFormCards>
    </DashboardFormWrapper>
  );
};

export const ContainersViewTopCards = ({ container }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <DashboardFormThreeCards>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          General Information
        </Typography>
        <InfoItem label={'Container Number'} value={container.number} />
        <InfoItem label={'Container Type'} value={formatContainerType(container.type)} />
        <InfoItem label={'Container Status'} value={containerStatusLabel[container.status]} />
      </DashboardFormCard>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          Gate-in
        </Typography>
        <InfoItem
          label={'Gate In Date'}
          value={
            container.gateIn.completedAt && momentTextFormat(container.gateIn.completedAt, user)
          }
        />
        <InfoItem
          label={'Driver Name'}
          value={[container.gateIn?.driver?.firstName, container.gateIn?.driver?.lastName]}
        />
        <InfoItem
          label={'Driver Phone Number'}
          value={formatPhoneNumber(container.gateIn?.driver?.phoneNumber)}
        />
        <InfoItem
          label={'License Plate Number'}
          value={
            container.gateIn?.driver?.licensePlateNumber === 'TRANSFERRED'
              ? ''
              : container.gateIn?.driver?.licensePlateNumber
          }
        />
        {showToRoles(['SUPER_ADMIN', 'YARD_MANAGER'], user) && (
          <InfoItem label={'Gated In By'} value={container.gatedInByName} />
        )}
        {container.gateIn?.driver?.licensePlateNumber === 'TRANSFERRED' && (
          <InfoItem label={'Gate In Type'} value={container.gateIn?.driver?.licensePlateNumber} />
        )}
        {container.gateIn?.transferredFrom && (
          <InfoItem label={'Gate In Code'} value={container.gateIn?.transferredFrom} />
        )}
      </DashboardFormCard>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          Gate-out
        </Typography>
        <InfoItem
          label={'Gate Out Date'}
          value={
            container.gateOut?.completedAt && momentTextFormat(container.gateOut?.completedAt, user)
          }
        />
        <InfoItem
          label={'Driver Name'}
          value={[container.gateOut?.driver?.firstName, container.gateOut?.driver?.lastName]}
        />
        <InfoItem
          label={'Driver Phone Number'}
          value={formatPhoneNumber(container.gateOut?.driver?.phoneNumber)}
        />
        <InfoItem
          label={'License Plate Number'}
          value={
            container.gateOut?.driver?.licensePlateNumber === 'TRANSFERRED'
              ? ''
              : container.gateOut?.driver?.licensePlateNumber
          }
        />
        {showToRoles(['SUPER_ADMIN', 'YARD_MANAGER'], user) && (
          <InfoItem label={'Gated Out By'} value={container.gatedOutByName} />
        )}
        {container.gateOut?.driver?.licensePlateNumber === 'TRANSFERRED' && (
          <InfoItem label={'Gate Out Type'} value={container.gateOut?.driver?.licensePlateNumber} />
        )}
        {container.gateOut?.transferredTo && (
          <InfoItem label={'Gate Out Code'} value={container.gateOut?.transferredTo} />
        )}
      </DashboardFormCard>
    </DashboardFormThreeCards>
  );
};

export const ContainersViewImages = ({ container }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const updateImagesMutation = useUpdateImagesMutation();
  const [photos, setPhotos] = useState<any>([]);
  const photosFiles = useRef<any>([]);
  const [oldPhotos, setOldPhotos] = useState<any>(container.images || []);
  const deletePhotos = useRef<any>([]);
  const photosInputRef = useRef<any>();
  const [zoomImageIndex, setZoomImageIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState<any>([]);
  const [, setStates, filteredStates] = useStatesWithFilterCounter(
    {
      photosLength: 0,
      isDeletePhotos: false,
    },
    {},
    0,
    false,
  );

  const handleChangePhotos = (newImages: any, files: any) => {
    const newPhotosValues = [...photos, ...newImages];
    if (filesFormatValidator(files, 'Image')) {
      enqueueSnackbar(filesFormatValidator(files, 'Image'), 'warning');
      return;
    }

    setPhotos(newPhotosValues);
    photosFiles.current = [...photosFiles.current, ...files];
    setStates('photosLength', newPhotosValues.length);
    photosInputRef.current.type = 'text';
    photosInputRef.current.type = 'file';
  };

  const handleZoomPhotoClick = (i: number) => {
    setGalleryImages([...oldPhotos.map(({ url }: any) => url), ...photos]);
    setZoomImageIndex(i);
    fullScreenButtonClick();
  };

  const handleDownloadImagesClick = () => {
    containersService.downloadImages(oldPhotos, container.number);
  };

  const handleDeletePhotoClick = (i: number) => {
    if (i - oldPhotos.length >= 0) {
      i = i - oldPhotos.length;
      photosFiles.current = photosFiles.current.filter((_: any, index: number) => index !== i);

      setPhotos(photos.filter((_: any, index: number) => index !== i));
      setStates('photosLength', photos.length - 1);
    } else {
      deletePhotos.current = [
        ...deletePhotos.current,
        { action: 'delete', name: oldPhotos[i].name },
      ];

      setOldPhotos(oldPhotos.filter((_: any, index: number) => index !== i));
      setStates('isDeletePhotos', true);
    }
  };

  const handleCancelClick = () => {
    photosFiles.current = [];
    deletePhotos.current = [];
    setPhotos([]);
    setOldPhotos(container.images || []);
    setStates('force', {
      photosLength: 0,
      isDeletePhotos: false,
    });
  };

  const handleSubmitClick = async () => {
    const data: any = {};

    if (photos.length > 10) {
      enqueueSnackbar('Max Upload Files is 10', 'warning');
      return;
    }
    data.photos = getFilesChanges(photos, photosFiles.current, deletePhotos.current);

    if (!data.photos.length) return;
    if (!container.id) return;

    await updateImagesMutation.mutate(
      { id: container.id, photos: data.photos },
      {
        onSuccess: (res) => {
          if (res) {
            enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.FILES_CHANGE, 'success');
            container.images = [
              ...oldPhotos,
              ...photos.map((file: any, i: string) => ({
                description: '',
                name: res[i].name || photosFiles.current[i].name,
                url: res[i].url || file,
              })),
            ];
            photosFiles.current = [];
            setOldPhotos(container.images);
            setPhotos([]);
            setStates('force', {
              photosLength: 0,
              isDeletePhotos: false,
            });
          } else enqueueSnackbar(MESSAGES.ERROR_BOUNDARY.DEFAULT, 'error');
        },
      },
    );
  };

  return (
    <>
      <ImageGallery photos={galleryImages} startIndex={zoomImageIndex} />
      <DashboardFormCard paperSize={'full-size'} rows={'max-content max-content 1fr'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          Images
        </Typography>
        <BorderLine space={'dashboard-form-card'} />
        <DashboardFormCustomColumn
          columns={'1fr 1fr 1fr'}
          alignContent={!oldPhotos.length && !photos.length ? 'center' : 'initial'}
        >
          {!oldPhotos.length && !photos.length && (
            <EmptyFilesMessage variant={'textM'} align={'center'} gridColumn={'1 / 4'}>
              This container does not have any images yet
            </EmptyFilesMessage>
          )}
          {[...oldPhotos, ...photos].map((img: any, i: any) => {
            let isHideDeleteButton = !!showToRoles(['CLIENT_MANAGER', 'CLIENT_DISPATCHER'], user);
            if (typeof img === 'object') {
              isHideDeleteButton = isHideDeleteButton || !img.name;
              img = img.url;
            }
            if (oldPhotos.length > i && !showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user)) {
              isHideDeleteButton = true;
            }
            return (
              <ImageGalleryCard
                key={i}
                img={img}
                handleZoomClick={() => handleZoomPhotoClick(i)}
                handleDeleteImageClick={() => handleDeletePhotoClick(i)}
                hideDeleteButton={isHideDeleteButton}
              />
            );
          })}
          <HideInput>
            {showToRoles('admin', user) && (
              <FileInput
                label={'Add Images'}
                inputRef={photosInputRef}
                inputProps={{
                  accept: '.jpeg, .jpg, .png, .webp',
                  multiple: true,
                }}
                handleChangeFiles={handleChangePhotos}
              />
            )}
          </HideInput>
        </DashboardFormCustomColumn>
        {showToRoles('admin', user) && (
          <>
            <BorderLine space={'dashboard-form-card-around'} />
            <DashboardFooterWithResize columns={'max-content 1fr'}>
              <Button
                size={'medium'}
                data-color={'primary'}
                onClick={() => photosInputRef.current.click()}
                justify={'left'}
              >
                Add Images
              </Button>
              <Button
                size={'medium'}
                data-color={'primary'}
                onClick={handleDownloadImagesClick}
                justify={'left'}
                disabled={!oldPhotos.length}
              >
                Download Images
              </Button>
              <Button
                size={'medium'}
                data-color={'white'}
                onClick={handleCancelClick}
                disabled={!filteredStates.photosLength && !filteredStates.isDeletePhotos}
              >
                Cancel
              </Button>
              {showToRoles('client', user) && <span />}
              <Button
                size={'medium'}
                data-color={'primary'}
                onClick={handleSubmitClick}
                disabled={!filteredStates.photosLength && !filteredStates.isDeletePhotos}
              >
                Save
              </Button>
            </DashboardFooterWithResize>
          </>
        )}
      </DashboardFormCard>
    </>
  );
};

export const ContainersViewDocuments = ({ container }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const updateDocumentsMutation = useUpdateDocumentsMutation();
  const [documents, setDocuments] = useState<any>([]);
  const documentsFiles = useRef<any>([]);
  const [oldDocuments, setOldDocuments] = useState<any>(container.documents || []);
  const deleteDocuments = useRef<any>([]);
  const documentsInputRef = useRef<any>();
  const [, setStates, filteredStates] = useStatesWithFilterCounter(
    {
      documentsLength: 0,
      isDeleteDocuments: false,
    },
    {},
    0,
    false,
  );

  const handleChangeDocuments = (newDocuments: any, files: any) => {
    const newDocumentsValues = [...documents, ...newDocuments];

    setDocuments(newDocumentsValues);
    documentsFiles.current = [...documentsFiles.current, ...files];
    setStates('documentsLength', newDocumentsValues.length);
    documentsInputRef.current.type = 'text';
    documentsInputRef.current.type = 'file';
  };

  const handleDownloadDocumentsClick = (i: number) => {
    const gallery = [...oldDocuments, ...documentsFiles.current];
    const galleryDownload = [...oldDocuments, ...documents];

    containersService.downloadDocument(
      galleryDownload[i].url || galleryDownload[i],
      gallery[i].name,
    );
  };

  const handleDeleteDocumentsClick = (i: number) => {
    if (i - oldDocuments.length >= 0) {
      i = i - oldDocuments.length;
      documentsFiles.current = documentsFiles.current.filter(
        (_: any, index: number) => index !== i,
      );

      setDocuments(documents.filter((_: any, index: number) => index !== i));
      setStates('documentsLength', documents.length - 1);
    } else {
      deleteDocuments.current = [
        ...deleteDocuments.current,
        { action: 'delete', name: oldDocuments[i].name },
      ];

      setOldDocuments(oldDocuments.filter((_: any, index: number) => index !== i));
      setStates('isDeleteDocuments', true);
    }
  };

  const handleCancelClick = () => {
    documentsFiles.current = [];
    deleteDocuments.current = [];
    setDocuments([]);
    setOldDocuments(container.documents || []);
    setStates('force', {
      documentsLength: 0,
      isDeleteDocuments: false,
    });
  };

  const handleSubmitClick = async () => {
    const data: any = {};

    if (documents.length > 10) {
      enqueueSnackbar('Max Upload Files is 10', 'warning');
      return;
    }
    data.documents = getFilesChanges(documents, documentsFiles.current, deleteDocuments.current);

    if (!data.documents.length) return;
    if (!container.id) return;

    await updateDocumentsMutation.mutate(
      { id: container.id, documents: data.documents },
      {
        onSuccess: (res) => {
          if (res) {
            enqueueSnackbar(MESSAGES.CONTAINERS.SUCCESS.FILES_CHANGE, 'success');
            container.documents = [
              ...oldDocuments,
              ...documents.map((file: any, i: string) => ({
                description: '',
                name: res[i].name || documentsFiles.current[i].name,
                url: res[i].url || file,
              })),
            ];
            documentsFiles.current = [];
            setOldDocuments(container.documents);
            setDocuments([]);
            setStates('force', {
              documentsLength: 0,
              isDeleteDocuments: false,
            });
          } else enqueueSnackbar(MESSAGES.ERROR_BOUNDARY.DEFAULT, 'error');
        },
      },
    );
  };

  return (
    <DashboardFormCard paperSize={'full-size'} rows={'max-content max-content 1fr'}>
      <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
        Documents
      </Typography>
      {!oldDocuments.length && !documents.length && <BorderLine space={'dashboard-form-card'} />}
      <DashboardFormCustomColumn
        columns={'1fr'}
        alignContent={!oldDocuments.length && !documents.length ? 'center' : 'initial'}
        withoutGap
      >
        {!oldDocuments.length && !documents.length && (
          <EmptyFilesMessage variant={'textM'} align={'center'} gridColumn={'1 / 4'}>
            This container does not have any documents yet
          </EmptyFilesMessage>
        )}
        <HideInput>
          {showToRoles('admin', user) && (
            <FileInput
              label={'Add Documents'}
              inputRef={documentsInputRef}
              inputProps={{
                accept: '*',
                multiple: true,
              }}
              handleChangeFiles={handleChangeDocuments}
            />
          )}
        </HideInput>
        {[...oldDocuments, ...documentsFiles.current].map((file: any, i: any) => {
          let handleDeleteFileClick = showToRoles('admin', user)
            ? () => handleDeleteDocumentsClick(i)
            : null;

          if (
            oldDocuments.length > i &&
            !showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN'], user)
          ) {
            handleDeleteFileClick = null;
          }

          return (
            <FileRow
              key={i}
              file={file}
              handleDownloadClick={() => handleDownloadDocumentsClick(i)}
              handleDeleteFileClick={handleDeleteFileClick}
            />
          );
        })}
      </DashboardFormCustomColumn>
      {showToRoles('admin', user) && (
        <>
          <BorderLine space={'dashboard-form-card-around'} />
          <DashboardFooterWithResize columns={'1fr'}>
            <Button
              size={'medium'}
              data-color={'primary'}
              onClick={() => documentsInputRef.current.click()}
              justify={'left'}
            >
              Add Documents
            </Button>
            <Button
              size={'medium'}
              data-color={'white'}
              onClick={handleCancelClick}
              disabled={!filteredStates.documentsLength && !filteredStates.isDeleteDocuments}
            >
              Cancel
            </Button>
            <Button
              size={'medium'}
              data-color={'primary'}
              onClick={handleSubmitClick}
              disabled={!filteredStates.documentsLength && !filteredStates.isDeleteDocuments}
            >
              Save
            </Button>
          </DashboardFooterWithResize>
        </>
      )}
    </DashboardFormCard>
  );
};
