import { QueryClient } from 'react-query';
import { Updater } from 'react-query/types/core/utils';
import axios from 'axios';
import Cookies from 'js-cookie';
import { enqueueSnackbar } from '../design-system';
import { authService } from '../services';
import { MESSAGES } from '../const';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

type RemoveCacheProps =
  | (
      | 'container'
      | 'gate-ins'
      | 'gate-outs'
      | 'yard-companies'
      | 'yard'
      | 'client'
      | 'employee'
      | string
    )[]
  | '*'
  | undefined;

export const removeCache = async (key: RemoveCacheProps) => {
  if (key === '*' || !key) {
    return queryClient.clear();
  }

  key.forEach((queryKey) => {
    queryClient.removeQueries([queryKey]);

    if (queryKey !== 'gate-ins' && queryKey !== 'gate-outs' && queryKey !== 'employee')
      queryClient.removeQueries([queryKey + '-short']);

    if (queryKey !== 'gate-ins' && queryKey !== 'gate-outs' && queryKey !== 'yard-companies')
      queryClient.removeQueries([queryKey + 's']);
  });
};

export const saveCache = <T>(key: string, data: Updater<T, T>) => {
  return queryClient.setQueryData<T>(key, data as Updater<T | undefined, T>);
};

export const getCache = <T>(key: string) => {
  return queryClient.getQueryData(key) as T;
};

// AXIOS INTERCEPTORS
let pending = 0;
axios.defaults.baseURL = process.env.REACT_APP_TEZPORT_API_URL;
const disabledTokensForRequests = [
  '/auth/login',
  '/auth/get-password-reset-instruction',
  '/auth/create-new-password',
  'storage.googleapis.com',
];

export const controlBackdropLoading = (value: number) => {
  pending += value;

  const backdropEl = document.querySelector('#backdrop-loading');
  const isLoading = backdropEl?.className.includes('loading');

  if (pending && !isLoading) {
    backdropEl?.classList.add('loading');
  }

  if (!pending && isLoading) {
    backdropEl?.classList.remove('loading');
  }
};

export const backdropLoadingAutoCall = () => {
  axios.interceptors.request.use(
    (config: any) => {
      if (!config.params || !config.params.notLoading) controlBackdropLoading(1);

      if (
        disabledTokensForRequests.every((link) => config.url.indexOf(link) === -1) &&
        !config.headers.Authorization
      ) {
        config.headers.Authorization = `Bearer ${Cookies.get('jwt')}`;
      }

      if (config.params && config.params.notLoading) {
        config.customData = { notLoading: config.params.notLoading };
        delete config.params.notLoading;
      }

      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response: any) => {
      const customData = response.config.customData;
      if (!customData || !customData.notLoading) controlBackdropLoading(-1);

      return response;
    },
    (error) => {
      const customData = error.config.customData;
      if (!customData || !customData.notLoading) controlBackdropLoading(-1);

      if (!error.response || !error.response.data || !error.response.data.message) {
        error.response = { data: { message: error.message } };
      }
      if (error.response.data.message.includes('Connection timed out')) {
        error.response.data.message = MESSAGES.ERROR_BOUNDARY.DEFAULT;
      }

      if (error.code === 'ERR_NETWORK') {
        enqueueSnackbar(MESSAGES.NETWORK.ERROR, 'error');
      }
      if (error.code === 'ERR_BAD_RESPONSE') {
        enqueueSnackbar(error.response.data.message, 'error');
      }
      if (error.code === 'ERR_BAD_REQUEST') error.statusCode = error.response.status;
      if (
        error.statusCode === 401 &&
        error.response.data.message === MESSAGES.AUTH.ERROR.INVALID_TOKEN
      ) {
        authService.logoutOnError();
      }
      if (
        error.statusCode === 404 &&
        error.response.data.message === MESSAGES.AUTH.ERROR.ACCOUNT_NOT_FOUND
      ) {
        authService.logoutOnError();
      }

      return Promise.reject(error);
    },
  );
};
