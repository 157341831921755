import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableDots,
  Pagination,
  Button,
  Chip,
  enqueueSnackbar,
  confirmEmployeeStatusModal,
} from '../../../design-system';
import { DashboardHeader } from '../../dashboard';
import { Filters } from './Filters';
import { useStore } from '../../../context';
import {
  useStatesWithFilterCounter,
  usePaginationState,
  useEmployeesQuery,
  useActivateEmployeeMutation,
  useDeactivateEmployeeMutation,
} from '../../../hooks';
import { showToRoles, formatPhoneNumber } from '../../../utils';
import {
  employeesTableCompanyTitle,
  employeesThreeDots,
  employeesRolesLabel,
  TABLE,
  MESSAGES,
  EmployeeAccountStatusToChipColors,
} from '../../../const';

interface EmployeesPageProps {
  innerScroll?: boolean;
}

export const EmployeesPage = styled.div<EmployeesPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const EmployeesHeader = ({ totalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Employees: {totalCount}
      </Typography>
      {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'CLIENT_MANAGER'], user) && (
        <Button size={'medium'} to={'/dashboard/employees/add'}>
          Add Employee
        </Button>
      )}
    </DashboardHeader>
  );
};

export const EmployeesBody = ({ setTotalCount }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const activateMutation = useActivateEmployeeMutation();
  const deactivateMutation = useDeactivateEmployeeMutation();
  const [openDotsMenuIndex, setOpenMenuDotsSelect] = useState(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter(
    {
      belongsTo: 'All',
      companyName: '',
      companyId: '',
      firstName: '',
      lastName: '',
      accountStatus: 'All',
    },
    {
      belongsTo: showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_MANAGER'], user)
        ? 'Yard'
        : 'Client',
      accountStatus: 'ACTIVE',
    },
  );

  const {
    data = { data: [], totalCount: 0 },
    isFetching,
    refetch,
  } = useEmployeesQuery({
    ...filteredStates,
    take,
    skip,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setOpenMenuDotsSelect(i);

    setOpenMenuDotsSelect(null);
  };

  const handleDotsOptionSelect = (name: any, val: any, id: any) => {
    let fc = null;
    if (val === 'ACTIVATE') {
      fc = () =>
        activateMutation.mutate(
          { userId: id },
          {
            onSuccess: async () => {
              enqueueSnackbar(MESSAGES.EMPLOYEE.SUCCESS.ACTIVATED, 'success');
              await refetch();
            },
            onError: () => {
              enqueueSnackbar(MESSAGES.ERROR_BOUNDARY.DEFAULT, 'error');
            },
          },
        );
    }
    if (val === 'DEACTIVATE') {
      fc = () =>
        deactivateMutation.mutate(
          { userId: id },
          {
            onSuccess: async () => {
              enqueueSnackbar(MESSAGES.EMPLOYEE.SUCCESS.DEACTIVATED, 'success');
              await refetch();
            },
            onError: () => {
              enqueueSnackbar(MESSAGES.ERROR_BOUNDARY.DEFAULT, 'error');
            },
          },
        );
    }

    if (val === 'ACTIVATE' || val === 'DEACTIVATE') {
      confirmEmployeeStatusModal.val = val;
      confirmEmployeeStatusModal.fc = fc;
      confirmEmployeeStatusModal.open();
    }
  };

  return (
    <TableWrapper columns={[7]}>
      <Filters
        setStates={setStates}
        {...states}
        belongsTo={states.belongsTo}
        handlePageChange={handlePageChange}
      />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder={true}
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
            <TableHeadCell align={'left'}>
              {employeesTableCompanyTitle[states.belongsTo]}
            </TableHeadCell>
            <TableHeadCell align={'left'}>Role</TableHeadCell>
            <TableHeadCell align={'left'}>First Name</TableHeadCell>
            <TableHeadCell align={'left'}>Last Name</TableHeadCell>
            <TableHeadCell align={'left'}>Email</TableHeadCell>
            <TableHeadCell align={'left'}>Phone Number</TableHeadCell>
            <TableHeadCell align={'left'}>Account Status</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={data.data.map((employee, i) => (
          <TableRow key={employee.user.id + i}>
            <TableIconCell component={'th'} align={'left'}>
              {employee.user.id !== user.id && (
                <TableDots
                  open={openDotsMenuIndex === i}
                  onToggle={() => handleDotsToggle(i)}
                  options={employeesThreeDots[states.accountStatus]}
                  onSelect={(val: any) => {
                    handleDotsOptionSelect(
                      `${employee.firstName} ${employee.lastName}`,
                      val,
                      employee.user.id,
                    );
                  }}
                />
              )}
            </TableIconCell>
            <TableCell align={'left'}>{employee.companyName}</TableCell>
            <TableCell align={'left'}>{employeesRolesLabel[employee.role]}</TableCell>
            <TableCell align={'left'}>{employee.user.firstName}</TableCell>
            <TableCell align={'left'}>{employee.user.lastName}</TableCell>
            <TableCell align={'left'}>{employee.user.email}</TableCell>
            <TableCell align={'left'}>{formatPhoneNumber(employee.user.phoneNumber)}</TableCell>
            <TableCell align={'left'}>
              <Chip
                size={'small'}
                label={employee.user.accountStatus}
                color={EmployeeAccountStatusToChipColors[employee.user.accountStatus]}
              />
            </TableCell>
          </TableRow>
        ))}
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
