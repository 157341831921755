import { Typography, Button, InfoItem } from '../../design-system';
import { DashboardHeader, DashboardFormCards, DashboardFormCard } from '../dashboard';
import { useStore } from '../../context';
import { showToRoles, momentTextFormat, formatPhoneNumber, formatContainerType } from '../../utils';

export const GateInsViewHeader = ({ request }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        {request.container.number}
      </Typography>
      <Button size={'medium'} data-color={'white'} to={-1}>
        Go Back
      </Button>
    </DashboardHeader>
  );
};

export const GateInsViewForm = ({ request }: any) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();

  return (
    <DashboardFormCards id={'gate-ins-view'}>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          General Information
        </Typography>
        <InfoItem label={'Container Number'} value={request.container.number} />
        <InfoItem label={'Container Type'} value={formatContainerType(request.container.type)} />
        <InfoItem
          label={'Created At'}
          value={request.createdAt && momentTextFormat(request.createdAt, user)}
        />
        <InfoItem
          label={'From'}
          value={request.validFrom && momentTextFormat(request.validFrom, user)}
        />
        <InfoItem label={'To'} value={request.validTo && momentTextFormat(request.validTo, user)} />
        <InfoItem
          label={'Completed At'}
          value={request.completedAt && momentTextFormat(request.completedAt, user)}
        />
        {showToRoles(['SUPER_ADMIN', 'YARD_MANAGER'], user) && (
          <>
            <InfoItem label={'Created By'} value={request.creatorName} />
            <InfoItem label={'Completed By'} value={request.authorizerName} />
          </>
        )}
        {request.driver?.licensePlateNumber === 'TRANSFERRED' && (
          <InfoItem label={'Gate In Type'} value={request.driver?.licensePlateNumber} />
        )}
        {request.transferredFrom && (
          <InfoItem label={'Gate In Code'} value={request.transferredFrom} />
        )}
      </DashboardFormCard>
      <DashboardFormCard paperSize={'full-size'}>
        <Typography variant={'textL'} weight={'semibold'} color={'--gray-90'}>
          Transport Information
        </Typography>
        <InfoItem label={'Transport'} value={'ground'} />
        <InfoItem
          label={'License Plate Number'}
          value={
            request.driver?.licensePlateNumber === 'TRANSFERRED'
              ? ''
              : request.driver?.licensePlateNumber
          }
        />
        <InfoItem
          label={'Driver Name'}
          value={[request.driver?.firstName, request.driver?.lastName]}
        />
        <InfoItem label={'Phone Number'} value={formatPhoneNumber(request.driver?.phoneNumber)} />
        <InfoItem label={'Release Number'} value={request.releaseNumber} />
        <InfoItem label={'Comment'} value={request.comment} />
      </DashboardFormCard>
    </DashboardFormCards>
  );
};
