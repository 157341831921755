import { ContainerType } from '../types';

export const documents = [
  { label: 'has a document', value: 'true' },
  { label: 'has not a document', value: 'false' },
];

export const containerTypes: { label: string; value: ContainerType }[] = [
  { label: "20'DC", value: '20DC' },
  { label: "20'HC", value: '20HC' },
  { label: "40'DC", value: '40DC' },
  { label: "40'HC", value: '40HC' },
  { label: "45'HC", value: '45HC' },
];

export const transportTypes = ['Ground', 'Rail', 'Port'];

export const emptyOrLoaded = [
  { label: 'empty', value: false },
  { label: 'loaded', value: true },
];

export const companies = ['Maersk', 'CMA CGM'];

export const containerStatues = [
  { label: 'gated in', value: 'GATED_IN' },
  { label: 'gated out', value: 'GATED_OUT' },
  { label: 'gate out requested', value: 'GATE_OUT_REQUESTED' },
];

export const requestStatusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Completed', value: 'COMPLETED' },
];

export type Roles =
  | 'SUPER_ADMIN'
  | 'YARD_COMPANY_ADMIN'
  | 'YARD_COMPANY_EMPLOYEE'
  | 'YARD_MANAGER'
  | 'YARD_DISPATCHER'
  | 'CLIENT_MANAGER'
  | 'CLIENT_DISPATCHER';

export const roles: { label: string; value: Roles }[] = [
  { label: 'Super Admin', value: 'SUPER_ADMIN' },
  { label: 'Yard Company Admin', value: 'YARD_COMPANY_ADMIN' },
  { label: 'Yard Company Employee', value: 'YARD_COMPANY_EMPLOYEE' },
  { label: 'Yard Manager', value: 'YARD_MANAGER' },
  { label: 'Yard Dispatcher', value: 'YARD_DISPATCHER' },
  { label: 'Client Manager', value: 'CLIENT_MANAGER' },
  { label: 'Client Dispatcher', value: 'CLIENT_DISPATCHER' },
];

export const recommendClientRoles: { label: string; value: Roles }[] = [
  { label: 'Yard Company Admin', value: 'YARD_COMPANY_ADMIN' },
  { label: 'Yard Company Employee', value: 'YARD_COMPANY_EMPLOYEE' },
  { label: 'Client Manager', value: 'CLIENT_MANAGER' },
];

export const clientRoles: { label: string; value: Roles }[] = [
  { label: 'Yard Company Admin', value: 'YARD_COMPANY_ADMIN' },
  { label: 'Yard Company Employee', value: 'YARD_COMPANY_EMPLOYEE' },
  { label: 'Client Manager', value: 'CLIENT_MANAGER' },
  { label: 'Client Dispatcher', value: 'CLIENT_DISPATCHER' },
];

export const tableDotsActions = [
  { label: 'Add Images', value: 'addphotos' },
  { label: 'Add Documents', value: 'adddocuments' },
  { label: 'Delete', value: 'delete' },
];

export const containersTableDotsAllActionsWithTransfer = [
  { label: 'Add Images', value: 'addphotos' },
  { label: 'Add Documents', value: 'adddocuments' },
  { label: 'Edit', value: 'edit' },
  { label: 'Request Gate Out', value: 'requestgateout' },
  { label: 'Transfer', value: 'transfer' },
];

export const containersTableDotsAllActions = [
  { label: 'Add Images', value: 'addphotos' },
  { label: 'Add Documents', value: 'adddocuments' },
  { label: 'Edit', value: 'edit' },
  { label: 'Request Gate Out', value: 'requestgateout' },
];

export const containersTableDotsActions = [
  { label: 'Add Images', value: 'addphotos' },
  { label: 'Add Documents', value: 'adddocuments' },
];

export const containersTableDotsClientActions = [
  { label: 'Request Gate Out', value: 'requestgateout' },
];

export const gateAllTableDotsActions = [
  { label: 'Complete', value: 'complete' },
  { label: 'Delete', value: 'delete' },
];

export const gateTableDotsActions = [{ label: 'Complete', value: 'complete' }];

export const storagePricingActionMenuMissingOptions = [
  { label: 'New Pricing', value: 'addPricing' },
];

export const storagePricingActionMenuOptions = [
  { label: 'New Pricing', value: 'addPricing' },
  { label: 'View And Update Pricing', value: 'viewPricing' },
];

export const storagePricingDotsViewActions = [{ label: 'View Pricing', value: 'viewPricing' }];

export const roleBasedCreateEmployeeOptions: any = {
  SUPER_ADMIN: {
    YardCompany: [
      { label: 'Admin', value: 'Admin' },
      { label: 'Employee', value: 'Employee' },
    ],
    Yard: [
      { label: 'Manager', value: 'Manager' },
      { label: 'Dispatcher', value: 'Dispatcher' },
    ],
    Client: [
      { label: 'Manager', value: 'Manager' },
      { label: 'Dispatcher', value: 'Dispatcher' },
    ],
  },
  YARD_COMPANY_ADMIN: {
    YardCompany: [{ label: 'Employee', value: 'Employee' }],
    Yard: [
      { label: 'Manager', value: 'Manager' },
      { label: 'Dispatcher', value: 'Dispatcher' },
    ],
  },
  YARD_MANAGER: {
    Yard: [{ label: 'Dispatcher', value: 'Dispatcher' }],
  },
  CLIENT_MANAGER: {
    Client: [{ label: 'Dispatcher', value: 'Dispatcher' }],
  },
};

export const employeesRolesFilterOptions: any = {
  SUPER_ADMIN: [
    { label: 'Yard Company Admin', value: 'YARD_COMPANY_ADMIN' },
    { label: 'Yard Company Employee', value: 'YARD_COMPANY_EMPLOYEE' },
    { label: 'Yard Manager', value: 'YARD_MANAGER' },
    { label: 'Yard Dispatcher', value: 'YARD_DISPATCHER' },
    { label: 'Client Manager', value: 'CLIENT_MANAGER' },
    { label: 'Client Dispatcher', value: 'CLIENT_DISPATCHER' },
  ],
  YARD_COMPANY_ADMIN: [
    { label: 'Yard Company Employee', value: 'YARD_COMPANY_EMPLOYEE' },
    { label: 'Yard Manager', value: 'YARD_MANAGER' },
    { label: 'Yard Dispatcher', value: 'YARD_DISPATCHER' },
  ],
  YARD_MANAGER: [{ label: 'Yard Dispatcher', value: 'YARD_DISPATCHER' }],
  CLIENT_MANAGER: [{ label: 'Client Dispatcher', value: 'CLIENT_DISPATCHER' }],
};

export const employeesFormCompanyRolesOptions: any = {
  SUPER_ADMIN: [
    { label: 'Yard Company', value: 'YardCompany' },
    { label: 'Yard', value: 'Yard' },
    { label: 'Client', value: 'Client' },
  ],
  YARD_COMPANY_ADMIN: [
    { label: 'Yard Company', value: 'YardCompany' },
    { label: 'Yard', value: 'Yard' },
  ],
  YARD_MANAGER: [{ label: 'Yard', value: 'Yard' }],
  CLIENT_MANAGER: [{ label: 'Client', value: 'Client' }],
};

export const employeetableDotsActions = [
  { label: 'activate', value: 'ACTIVATE' },
  { label: 'deactivate', value: 'DEACTIVATE' },
];

export const employeesAccountStatus = ['ACTIVE', 'DEACTIVATED'];

export const employeesThreeDots: any = {
  ACTIVE: ['DEACTIVATE'],
  DEACTIVATED: ['ACTIVATE'],
};
