import moment from 'moment';
import {
  TableFilters,
  TableFilterSelect,
  InputContainer,
  DatePicker,
  BelongsToFilterSelects,
} from '../../design-system';
import { extendContainerValidator } from '../../utils';
import { containerTypes, requestStatusOptions, INPUT_ERRORS } from '../../const';

interface Props {
  containerNumber?: string;
  type?: any;
  containerType?: any;
  clientName?: any;
  createdAt?: any;
  startDate?: any;
  endDate?: any;
  completedAt?: any;
  driverName?: string;
  licensePlateNumber?: string;
  transport?: any;
  status?: any;
  handleSort: (column: string, isSet: boolean) => void;
  setStates: any;
  withoutActions?: boolean;
}

export const Filters: React.FC<Props> = ({
  containerNumber = '',
  containerType,
  startDate,
  endDate,
  status,
  handleSort,
  setStates,
  withoutActions,
}: Props) => {
  return (
    <TableFilters>
      {!withoutActions && <span className={'remove-dots-column'} />}
      <BelongsToFilterSelects
        handleChange={(obj) => {
          setStates('force', obj);
        }}
      />
      <InputContainer
        label={'Container number'}
        data-size={'medium'}
        defaultValue={containerNumber}
        onChange={(e) => {
          const value = e.target.value.toUpperCase();
          setStates('containerNumber', value);
        }}
        helperText={
          containerNumber.length !== 0 && containerNumber.length < 3
            ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER_MIN
            : !extendContainerValidator(containerNumber)
            ? INPUT_ERRORS.NOT_VALID.CONTAINER_NUMBER_SHORT
            : ''
        }
        alwaysShowHelperText
      />
      <TableFilterSelect
        label={'Container type'}
        options={containerTypes}
        value={containerType}
        onChange={(e) => setStates('force', { containerType: e.target.value })}
      />
      <TableFilterSelect
        label={'Status'}
        options={requestStatusOptions}
        value={status}
        onChange={(e) => {
          const otherData: any = {};
          if (e.target.value !== 'COMPLETED') otherData.completedAt = null;
          if (e.target.value === 'COMPLETED') {
            handleSort('completedAt', true);
          } else handleSort('createdAt', true);

          setStates('force', {
            status: e.target.value,
            startDate: null,
            endDate: null,
            ...otherData,
          });
        }}
      />
      {status !== 'All' &&
        [status].map(() => (
          <DatePicker
            key={Math.random()}
            label={'From'}
            data-size={'medium'}
            defaultValue={startDate}
            onChange={(e) => setStates('force', { startDate: e })}
            minDate={status === 'ACTIVE' ? moment() : null}
            maxDate={
              status === 'COMPLETED'
                ? endDate
                  ? moment(endDate)
                  : moment()
                : endDate
                ? moment(endDate)
                : null
            }
          />
        ))}
      {status !== 'All' &&
        [status].map(() => (
          <DatePicker
            key={Math.random()}
            label={'To'}
            data-size={'medium'}
            defaultValue={endDate}
            onChange={(e) => setStates('force', { endDate: e })}
            minDate={
              status === 'ACTIVE'
                ? startDate
                  ? moment(startDate)
                  : moment()
                : startDate
                ? moment(startDate)
                : null
            }
            maxDate={status === 'COMPLETED' ? moment() : null}
          />
        ))}
    </TableFilters>
  );
};
