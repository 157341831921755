import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableDots,
  Pagination,
  Chip,
} from '../../design-system';
import { DashboardHeader } from '../dashboard';
import { Filters } from './Filters';
import { useStore } from '../../context';
import { momentTextFormat, showToRoles } from '../../utils';
import {
  useStatesWithFilterCounter,
  usePaginationState,
  useStoragePricingsQuery,
} from '../../hooks';
import { saveCache } from '../../services';
import {
  storagePricingActionMenuMissingOptions,
  storagePricingActionMenuOptions,
  storagePricingDotsViewActions,
  StoragePricingStatusToLabels,
  StoragePricingStatusToChipColors,
  TABLE,
} from '../../const';
import { StoragePricingGetAllResponse } from '../../types';

interface StoragePricingsPageProps {
  innerScroll?: boolean;
}

export const StoragePricingsPage = styled.div<StoragePricingsPageProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

interface StoragePricingsHeaderProps {
  totalCount: number;
}

export const StoragePricingsHeader = ({ totalCount }: StoragePricingsHeaderProps) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Storage Pricing: {totalCount}
      </Typography>
    </DashboardHeader>
  );
};

interface StoragePricingsBodyProps {
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
}

export const StoragePricingsBody = ({ setTotalCount }: StoragePricingsBodyProps) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const navigate = useNavigate();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const [openDotsMenuIndex, setDotsMenuIndex] = useState<null | number>(null);
  const [states, setStates, filteredStates] = useStatesWithFilterCounter({
    country: '',
    city: '',
    yardId: '',
    clientId: '',
  });

  const { data = { data: [], totalCount: 0 }, isFetching } = useStoragePricingsQuery({
    ...filteredStates,
    take,
    skip,
  });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: number) => {
    if (openDotsMenuIndex !== i) return setDotsMenuIndex(i);

    setDotsMenuIndex(null);
  };

  const handleDotsOptionSelect = async (
    row: StoragePricingGetAllResponse['data'][number],
    selectedOption: string,
    id: string,
  ) => {
    if (selectedOption === 'addPricing') {
      saveCache('storage-pricing-add', {
        yardName: row.yard.name,
        clientName: row.client.code,
      });
      navigate(`/dashboard/storage-pricing/add/${row.id}`);
    }
    if (selectedOption === 'viewPricing') {
      navigate(`/dashboard/storage-pricing/view/${id}`);
    }
  };

  const handleRowClick = (to: string, pricingStatus: string) => {
    if (pricingStatus === 'MISSING') return;
    if (openDotsMenuIndex !== null) return;
    navigate(to);
  };

  return (
    <TableWrapper
      autoColumn={5}
      columns={showToRoles(['SUPER_ADMIN'], user) ? [7, 17, 20, 20, 20, 20] : [7, 17, 20, 20, 20]}
    >
      <Filters user={user} setStates={setStates} {...states} />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder={true}
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
            <TableHeadCell align={'left'}>Client Name</TableHeadCell>
            <TableHeadCell align={'left'}>Yard Name</TableHeadCell>
            {showToRoles(['SUPER_ADMIN'], user) && (
              <TableHeadCell align={'left'}>Yard Company</TableHeadCell>
            )}
            <TableHeadCell align={'left'}>Pricing Status</TableHeadCell>
            <TableHeadCell align={'left'}>Effective Date</TableHeadCell>
            <TableHeadCell align={'left'}>Free Days</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={data.data.map((data, i) => (
          <TableRow
            key={'storage-pricing' + i}
            onClick={() =>
              handleRowClick(
                `/dashboard/storage-pricing/view/${data.storagePricing.id}`,
                data.storagePricing.pricingStatus,
              )
            }
          >
            {showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) ||
            data.storagePricing.pricingStatus === 'ACTIVE' ? (
              <TableIconCell component={'th'} align={'left'}>
                <TableDots
                  open={openDotsMenuIndex === i}
                  onToggle={() => handleDotsToggle(i)}
                  options={
                    data.storagePricing.pricingStatus === 'MISSING'
                      ? showToRoles(
                          ['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'],
                          user,
                        )
                        ? storagePricingActionMenuMissingOptions
                        : []
                      : showToRoles(
                          ['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'],
                          user,
                        )
                      ? storagePricingActionMenuOptions
                      : storagePricingDotsViewActions
                  }
                  onSelect={(selectedOption) => {
                    handleDotsOptionSelect(
                      data as StoragePricingGetAllResponse['data'][number],
                      selectedOption,
                      data.storagePricing.id,
                    );
                  }}
                />
              </TableIconCell>
            ) : (
              <TableIconCell component={'th'} align={'left'} />
            )}
            <TableCell component={'th'} align={'left'}>
              {data.client.code}
            </TableCell>
            {showToRoles(['SUPER_ADMIN'], user) ? (
              <>
                <TableCell align={'left'}>{data.yard.name}</TableCell>
                <TableCell align={'left'}>{data.yard.yardCompany.legalName}</TableCell>
              </>
            ) : (
              <TableCell align={'left'}>{data.yard.name}</TableCell>
            )}
            <TableCell align={'left'}>
              <Chip
                size={'small'}
                label={StoragePricingStatusToLabels[data.storagePricing.pricingStatus]}
                color={StoragePricingStatusToChipColors[data.storagePricing.pricingStatus]}
              />
            </TableCell>
            <TableCell align={'left'}>
              {data.storagePricing.activationDate
                ? momentTextFormat(data.storagePricing.activationDate, user)
                : ''}
            </TableCell>
            <TableCell align={'left'}>
              {data.storagePricing.freeDays}
              {' Days'}
            </TableCell>
          </TableRow>
        ))}
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
