import { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { sendContractModal } from '../modal';
import { SearchableSelect } from '.';
import { useStore } from '../../context';
import { showToRoles } from '../../utils';
import { useYardOptionsQuery } from '../../hooks';
import { clientYardService } from '../../services';
import { INPUT_ERRORS } from '../../const';

export const BelongsToSelectsBase = styled.div<{ isAdmin: boolean; hidePlaces?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 2.8rem;
  grid-row-gap: 2.4rem;

  ${(props) =>
    props.isAdmin &&
    !props.hidePlaces &&
    css`
      & > *:nth-last-child(1) {
        grid-column: 1 / 4;
      }
    `}

  ${(props) =>
    props.hidePlaces &&
    css`
      grid-template-columns: 1fr 1fr;
    `}


  ${(props) =>
    !props.isAdmin &&
    props.hidePlaces &&
    css`
      grid-template-columns: 1fr;
    `}
`;

interface BelongsToSelectsProps {
  country?: string;
  city?: string;
  yardId?: string;
  yardName?: string;
  clientId?: string;
  clientName?: string;
  hidePlaces?: boolean;
  disabled?: boolean;
  handleYardCompanyChange?: (obj: any) => void;
  handleYardChange: (obj: any) => void;
  handleClientChange: (obj: any) => void;
  isSubmitClicked: boolean | any;
}

export const BelongsToSelects: React.FC<BelongsToSelectsProps> = ({
  country: defaultCountry = '',
  city: defaultCity = '',
  yardId: defaultYardId = '',
  yardName: defaultYardName,
  clientId: defaultClientId = '',
  clientName: defaultClientName,
  hidePlaces,
  disabled,
  handleYardCompanyChange,
  handleYardChange,
  handleClientChange,
  isSubmitClicked,
}: BelongsToSelectsProps) => {
  const {
    state: {
      user: { data: user },
    },
  } = useStore();
  const yardCompanyId = useRef('');
  const [country, setCountry] = useState(defaultCountry);
  const [city, setCity] = useState(defaultCity);
  const [yardName, setYardName] = useState('');
  const yardId = useRef('');
  const [clientName, setClientName] = useState();
  const clientId = useRef('');

  const { data: yardQuery } = useYardOptionsQuery(
    [
      'yards-short',
      'assigned-yards' +
        (yardCompanyId.current &&
        showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
          ? yardCompanyId.current
          : clientId.current
          ? clientId.current
          : ''),
    ],
    {
      user,
      yardCompanyId: yardCompanyId.current,
      clientId: clientId.current,
      country,
      city,
    },
  );

  useEffect(() => {
    const yardCompanyObj = {
      label: showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
        ? user.yardCompanyEmployee.yardCompany.legalName
        : '',
      id: showToRoles(['YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)
        ? user.yardCompanyEmployee.yardCompanyId
        : '',
    };
    const yardObj = {
      label: showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user)
        ? user.yardEmployee.yard.name
        : defaultYardName,
      id: showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user)
        ? user.yardEmployee.yardId
        : defaultYardId,
    };
    const clientObj = {
      label: showToRoles('client', user) ? user.clientEmployee.client.legalName : defaultClientName,
      id: showToRoles('client', user) ? user.clientEmployee.clientId : defaultClientId,
    };

    handleYardCompanySelect(yardCompanyObj);
    handleCountrySelect({ label: defaultCountry });
    handleCitySelect({ label: defaultCity });
    handleYardSelect(yardObj);
    handleClientSelect(clientObj);
  }, []);

  const handleYardCompanySelect = (obj: any) => {
    yardCompanyId.current = obj.id;
    handleYardCompanyChange && handleYardCompanyChange(obj);

    handleCountrySelect({ label: '', id: '' });
  };

  const handleCountrySelect = (obj: any) => {
    setCountry(obj.label);

    if (
      showToRoles(
        [
          'SUPER_ADMIN',
          'YARD_COMPANY_ADMIN',
          'YARD_COMPANY_EMPLOYEE',
          'CLIENT_MANAGER',
          'CLIENT_DISPATCHER',
        ],
        user,
      )
    ) {
      handleCitySelect({ label: '', id: '' });
    }
  };

  const handleCitySelect = (obj: any) => {
    setCity(obj.label);

    if (
      showToRoles(
        [
          'SUPER_ADMIN',
          'YARD_COMPANY_ADMIN',
          'YARD_COMPANY_EMPLOYEE',
          'CLIENT_MANAGER',
          'CLIENT_DISPATCHER',
        ],
        user,
      )
    ) {
      handleYardSelect({ label: '', id: '' });
    }
  };

  const handleYardSelect = (obj: any) => {
    if (obj.isNew && showToRoles('client', user)) {
      sendContractModal.yardId = obj.id;
      sendContractModal.open();
      return;
    }

    yardId.current = obj.id;
    setYardName(obj.label);
    handleYardChange && handleYardChange(obj);
    if (showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user)) {
      handleClientSelect({ label: '', id: '' });
    }
  };

  const handleClientSelect = (obj: any) => {
    clientId.current = obj.id;
    setClientName(obj.label);
    handleClientChange && handleClientChange(obj);
  };

  return (
    <BelongsToSelectsBase
      isAdmin={!!showToRoles('admin', user)}
      hidePlaces={!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && hidePlaces}
    >
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && !hidePlaces && (
        <SearchableSelect
          selectedOptionLabel={country}
          label={'Country'}
          placeholder={'Select Country'}
          onSelect={handleCountrySelect}
          options={yardQuery.country}
          selectedAreasEqaul={{ key: 'id', keys: [country] }}
          withClearButton
          disabled={disabled}
        />
      )}
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && !hidePlaces && (
        <SearchableSelect
          selectedOptionLabel={city}
          label={'City'}
          placeholder={'Select City'}
          onSelect={handleCitySelect}
          options={yardQuery.city}
          selectedAreasEqaul={{ key: 'id', keys: [city] }}
          withClearButton
          disabled={disabled}
        />
      )}
      {!showToRoles(['YARD_MANAGER', 'YARD_DISPATCHER'], user) && (
        <SearchableSelect
          selectedOptionLabel={yardName}
          label={disabled ? 'Yard' : 'Yard*'}
          placeholder={'Select Yard'}
          onSelect={handleYardSelect}
          options={yardQuery.yard}
          selectedAreasEqaul={{ key: 'id', keys: [yardName] }}
          disabled={disabled}
          error={isSubmitClicked && !yardName}
          helperText={isSubmitClicked && !yardName && INPUT_ERRORS.REQUIRED.YARD_NAME}
        />
      )}
      {showToRoles('admin', user) && (
        <SearchableSelect
          selectedOptionLabel={clientName}
          label={disabled ? 'Client' : 'Client*'}
          placeholder={'Select Client'}
          onSelect={handleClientSelect}
          queryFn={() => clientYardService.assignedClients({ id: yardId.current })}
          queryKey={['clients', 'assigned-clients' + yardId.current]}
          selectedAreasEqaul={{ key: 'id', keys: [clientId.current] }}
          disabled={
            disabled ||
            (!!showToRoles(['SUPER_ADMIN', 'YARD_COMPANY_ADMIN', 'YARD_COMPANY_EMPLOYEE'], user) &&
              !yardId.current)
          }
          error={isSubmitClicked && !clientName}
          helperText={isSubmitClicked && !clientName && INPUT_ERRORS.REQUIRED.CLIENT_NAME}
        />
      )}
    </BelongsToSelectsBase>
  );
};
