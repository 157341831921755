import { useQuery } from '../use-query';
import { storagePricingsService } from '../../../services';
import { FiltersWithPaginationProps, SortDirection } from '../../../types';
import { StoragePricingStatusToLabels } from '../../../const';

export const useStoragePricingsQuery = (keys: FiltersWithPaginationProps) => {
  return useQuery(
    ['storage-pricings', JSON.stringify(keys)],
    () => {
      return storagePricingsService
        .getAll({
          params: keys,
        })
        .then((res) => res.data)
        .then((res) => {
          const data = res.data.map((data) => {
            const { storagePricing } = data;
            if (!storagePricing) {
              return {
                ...data,
                storagePricing: {
                  id: '',
                  pricingStatus: 'MISSING' as keyof typeof StoragePricingStatusToLabels,
                  freeDays: 0,
                  activationDate: null,
                },
              };
            }

            const i = storagePricing.pricing.findIndex(
              (pricing) => pricing.id === storagePricing.activePricingId,
            );

            storagePricing.freeDays = storagePricing.pricing[i].freeDays;
            storagePricing.activationDate = storagePricing.pricing[i].activationDate;
            storagePricing.pricingStatus = 'ACTIVE';

            return { ...data, storagePricing };
          });
          return { ...res, data };
        });
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );
};

export const useStoragePricingQuery = (id = '', sortDirection?: SortDirection) => {
  return useQuery(
    ['storage-pricing', id],
    () => {
      return storagePricingsService
        .get(id)
        .then((res) => res.data.data)
        .then((res) => {
          if (sortDirection) {
            res.pricing = res.pricing.sort((a, b) => {
              if (sortDirection === 'asc')
                return new Date(a.activationDate).getTime() - new Date(b.activationDate).getTime();

              return new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime();
            });
          }

          return res;
        });
    },
    {
      initialData: { data: {} },
    },
  );
};
