import axios from 'axios';
import { GateInGetAllQuery } from '../../types';

class GateInsService {
  getAll = (props: any) => axios.get<GateInGetAllQuery>(`/gate-ins`, props);

  get = (id: any) => axios.get(`/gate-ins/${id}`);

  add = (props: any) => axios.post(`/gate-ins`, props);

  complete = async ({ id, props }: any) => axios.patch(`/gate-ins/${id}`, props);

  delete = (id: any) => axios.delete(`/gate-ins/${id}`);
}

export const gateInsService = new GateInsService();
