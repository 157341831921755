import axios from 'axios';
import {
  StoragePricingAddMutationRequest,
  StoragePricingGetAllRequest,
  StoragePricingGetAllResponse,
  StoragePricingGetOneResponse,
  StoragePricingUpdateMutationRequest,
} from '../../types';

class StoragePricingsService {
  getAll = (props: StoragePricingGetAllRequest) =>
    axios.get<StoragePricingGetAllResponse>(`/storage-pricings`, props);

  get = (id: string) => axios.get<StoragePricingGetOneResponse>(`/storage-pricings/${id}`);

  addPricing = ({ id, ...props }: StoragePricingAddMutationRequest) =>
    axios.post(`/storage-pricings/${id}`, props);

  updatePricing = ({ id, ...props }: StoragePricingUpdateMutationRequest) =>
    axios.patch(`/storage-pricings/${id}`, props);
}

export const storagePricingsService = new StoragePricingsService();
