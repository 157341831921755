import { Moment } from 'moment';
import { PricingDetails, ContainerType } from '../types';

export const pricingDetails: PricingDetails = ['loaded', 'empty', 'loadedCrane', 'emptyCrane'];

interface StoragePricingBase {
  loaded: number;
  empty: number;
  loadedCrane: number;
  emptyCrane: number;
}

export type StoragePricing = Record<ContainerType, StoragePricingBase> & {
  freeDays: number;
  effectiveDate: Moment | string;
};

interface Pricing {
  loaded: number;
  empty: number;
  loadedCrane: number;
  emptyCrane: number;
}

export interface StoragePricingContainers {
  '20DC': Pricing;
  '20HC': Pricing;
  '40DC': Pricing;
  '40HC': Pricing;
  '45HC': Pricing;
}

const PRICING = {
  loaded: 0,
  empty: 0,
  loadedCrane: 0,
  emptyCrane: 0,
};

export const STORAGE_PRICING = {
  freeDays: 0,
  '20DC': PRICING,
  '20HC': PRICING,
  '40DC': PRICING,
  '40HC': PRICING,
  '45HC': PRICING,
};
