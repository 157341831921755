import { useRef, useState, useEffect } from 'react';

type CloseWithOutsideClick = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  React.MutableRefObject<any>,
];

export const useCloseWithOutsideClick = (): CloseWithOutsideClick => {
  const [visible, setVisible] = useState(false);
  const menuRef = useRef<any>();

  const handleOutsideClick = (e: Event) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) setVisible(false);
  };

  useEffect(() => {
    if (visible) window.addEventListener('mousedown', handleOutsideClick);
    return () => window.removeEventListener('mousedown', handleOutsideClick);
  }, [visible]);

  return [visible, setVisible, menuRef];
};
