import { useQuery } from '../../../hooks';
import { employeesService } from '../../../services';

enum CompanyNameKeys {
  YardCompanyAdmin = 'yardCompany',
  YardCompanyEmployee = 'yardCompany',
  ClientManager = 'client',
  ClientDispatcher = 'client',
}

export const useEmployeesQuery = ({ belongsTo, ...keys }: any = {}) =>
  useQuery(
    ['employees', belongsTo, JSON.stringify(keys)],
    () => {
      const params = { ...keys };

      delete params.companyName;
      delete params.companyId;
      delete params.yardCompanyName;
      if (belongsTo === 'Client') params.clientId = keys.companyId;
      if (belongsTo === 'Yard') params.yardId = keys.companyId;
      if (belongsTo === 'YardCompany') params.yardCompanyId = keys.companyId;

      return employeesService
        .getAll({
          belongsTo,
          params,
        })
        .then((res) => {
          res.data.data = res.data.data.map((data) => {
            if (data.role === 'YardManager' || data.role === 'YardDispatcher') {
              data.companyName = data.yard.name;
            } else {
              data.companyName = data[CompanyNameKeys[data.role]].legalName;
            }

            return data;
          });

          return res.data;
        });
    },
    { initialData: { data: [], totalCount: 0 }, keepPreviousData: true },
  );
