import axios from 'axios';
import { EmployeeGetAllQuery } from '../../types';

interface CreateEmployeeProps {
  companyId: string;
  companyType: 'YardCompany' | 'Yard' | 'Client';
  email: string;
  employeeRole:
    | 'Admin'
    | 'Employee'
    | 'Manager'
    | 'Dispatcher'
    | 'CompanyAdmin'
    | 'CompanyEmployee';
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
  phoneNumber: string;
  [x: string]: any;
}

export interface UpdateStatusProps {
  userId: string;
  status?: string;
  METHOD?: 'patch' | 'post';
}

interface UpdateStatusRequiredProps extends UpdateStatusProps {
  METHOD: 'patch' | 'post';
}

const getAllEmployeesLink: any = {
  YardCompany: '/yard-company-employees',
  Yard: '/yard-employees',
  Client: '/client-employees',
};

class EmployeesService {
  getAll = ({ belongsTo, ...data }: any) =>
    axios.get<EmployeeGetAllQuery>(`${getAllEmployeesLink[belongsTo]}`, {
      ...data,
    });

  create = ({
    companyType,
    email,
    employeeRole,
    firstName,
    lastName,
    password,
    passwordConfirm,
    phoneNumber,
    employeeRequestLink,
    ...props
  }: CreateEmployeeProps) =>
    axios
      .post(`${employeeRequestLink}`, {
        ...props,
        firstName,
        lastName,
        password,
        passwordConfirmation: passwordConfirm,
        email,
        phoneNumber,
        role: companyType + employeeRole,
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });

  updateStatus = async ({ userId, status, METHOD }: UpdateStatusRequiredProps) => {
    return axios[METHOD](`/users/${status}`, {
      userId,
    });
  };
}

export const employeesService = new EmployeesService();
