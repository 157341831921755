import { useEffect } from 'react';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputAdornment } from '@mui/material';
import { Clear as ClearIcon, CalendarMonth as CalendarMonthIcon } from '@mui/icons-material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  DateField,
} from '@mui/x-date-pickers';
import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from '@mui/x-date-pickers/TimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import { Input, theme as inputTheme } from './Input';
import { IconButton } from '../button';
import { useCloseWithOutsideClick } from '../../hooks';
import { isValidDate } from '../../utils';
import { INPUT_ERRORS } from '../../const';

const ComponentVariants = {
  DatePicker: MuiDatePicker,
  DateField,
  DateRangePicker,
};

const defaultMuiDateTheme = {
  styleOverrides: {
    root: {
      display: 'grid',
      gridAutoRows: 'max-content',
      padding: '2.8rem',
      paddingBottom: '2rem',
      width: 'initial',
      maxHeight: '39.6rem',

      '&.MuiDateRangeCalendar-root': {
        gridAutoFlow: 'column',
        '& > div:nth-of-type(2)': {
          borderRight: '0.1rem solid var(--gray-50)',
          marginRight: '2.8rem',
          paddingRight: '2.8rem',
        },
      },
      '&.MuiDateRangeCalendar-root > div:nth-of-type(1)': {
        display: 'none',
      },
      '& .MuiPickersCalendarHeader-root, & .MuiPickersArrowSwitcher-root': {
        height: '2.4rem',
        minHeight: '2.4rem',
        maxHeight: '2.4rem',
        padding: 0,
        margin: 0,

        '& .MuiPickersCalendarHeader-label, & > span.MuiTypography-subtitle1': {
          fontFamily: 'Inter',
          fontSize: 'var(--font-size-3)',
          lineHeight: 'var(--line-height-3)',
          color: 'var(--gray-100)',
        },
        '& .MuiPickersCalendarHeader-switchViewButton': {
          padding: 0,

          '& > svg': {
            width: '2.4rem',
            height: '2.4rem',
            color: 'var(--gray-100)',
          },
        },
        '& .MuiPickersArrowSwitcher-spacer': {
          width: '3.6rem',
        },
        '& .MuiPickersArrowSwitcher-button': {
          padding: 0,
          color: 'var(--primary-100)',

          '& > svg': {
            width: '2.4rem',
            height: '2.4rem',
          },
        },
      },
      '& .MuiPickersCalendarHeader-root + .MuiPickersFadeTransitionGroup-root, & .MuiPickersCalendarHeader-root + div, & .MuiPickersArrowSwitcher-root + div':
        {
          marginTop: '2rem',

          '& .MuiDayCalendar-header': {
            display: 'grid',
            gridAutoColumns: '4rem',
            gridGap: '0.4rem',
            gridAutoFlow: 'column',

            '& > span': {
              width: '100%',
              height: '2rem',
              margin: 0,

              fontFamily: 'Inter',
              fontSize: 'var(--font-size-2)',
              lineHeight: 'var(--line-height-2)',
              color: 'var(--gray-60)',
            },
          },
          '& .MuiPickersSlideTransition-root': {
            marginTop: '1.6rem',
            overflow: 'hidden',
            minWidth: '30.4rem',
            minHeight: '26rem',

            '& .MuiDayCalendar-monthContainer': {
              display: 'grid',
              gridRowGap: '0.4rem',
              alignContent: 'start',
              height: '100%',

              '& .MuiDayCalendar-weekContainer': {
                justifyContent: 'space-between',
                margin: 0,

                '& button': {
                  width: '4rem',
                  height: '4rem',
                  margin: 0,
                  fontFamily: 'Inter',
                  fontSize: 'var(--font-size-3)',
                  lineHeight: 'var(--line-height-3)',
                  color: 'var(--gray-100)',
                  transition: 'color 0.3s, background-color 0.3s',
                  borderRadius: '0.4rem',
                  transform: 'initial',

                  '&.MuiPickersDay-today': {
                    color: 'var(--primary-100)',
                    border: 'solid 0.2rem var(--primary-100)',
                  },
                  '&.Mui-selected': {
                    color: 'var(--white)',
                    backgroundColor: 'var(--primary-80)',
                    fontWeight: 500,
                  },
                  '&:hover': {
                    color: 'var(--primary-100)',
                    backgroundColor: 'var(--primary-40)',
                  },

                  '& .MuiTouchRipple-child': {
                    borderRadius: '0.4rem',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'var(--gray-40)',
                    color: 'var(--gray-70)',
                  },
                },

                '& > .MuiDateRangePickerDay-root': {
                  width: '4rem',
                  height: '4rem',

                  '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
                    backgroundColor: 'initial',
                  },

                  '& > *.MuiDateRangePickerDay-rangeIntervalDayPreview button:not(:hover),& button.MuiDateRangePickerDay-dayInsideRangeInterval:not(:hover)':
                    {
                      color: 'var(--primary-100)',
                      backgroundColor: 'var(--primary-40)',
                    },

                  '& > *': {
                    border: 'initial',
                  },
                  '& .MuiTouchRipple-root': {
                    transform: 'scale(1)',
                  },
                },
              },
            },
          },
        },
      '& .MuiYearCalendar-root': {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 25%)',
        width: '30.4rem',
        padding: 0,

        '& button': {
          fontFamily: 'Inter',
          fontSize: 'var(--font-size-3)',
          lineHeight: 'var(--line-height-3)',
          color: 'var(--gray-100)',
          transition: 'color 0.3s, background-color 0.3s',

          '&.Mui-selected': {
            color: 'var(--white)',
            backgroundColor: 'var(--primary-80)',
            fontWeight: 500,
          },
          '&:hover': {
            color: 'var(--primary-100)',
            backgroundColor: 'var(--primary-40)',
          },
        },
      },
    },
  },
};

const theme = (selectedDays?: number[]) =>
  createTheme({
    components: {
      ...inputTheme.components,
      MuiDateRangeCalendar: defaultMuiDateTheme,
      MuiDateCalendar: defaultMuiDateTheme,
      MuiMultiInputDateRangeField: {
        styleOverrides: {
          root: {
            '& > p': {
              fontFamily: 'Inter',
              fontSize: 'var(--font-size-2)',
              lineHeight: 'var(--line-height-2)',
              color: 'var(--gray-80)',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            border: '0.1rem solid var(--gray-50)',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            ...selectedDays?.reduce((obj: any, timestamp) => {
              obj[`&[data-timestamp="${timestamp}"]`] = {
                color: 'var(--primary-100) !important',
                border: 'solid 0.2rem var(--primary-100)',
              };
              return obj;
            }, {}),
          },
        },
      },
    },
  });

interface DatePickerProps extends MuiDatePickerProps<any> {
  label: string;
  'data-size'?: 'small' | 'medium' | 'large';
  calendars?: number;
  variant?: keyof typeof ComponentVariants;
  error?: boolean;
  helperText?: string;
  setRef?: any;
  withoutClearIcon?: boolean;
  selectedDays?: Date[];
}

export const DatePicker: React.FC<DatePickerProps> = ({
  label,
  'data-size': dataSize = 'large',
  variant = 'DatePicker',
  calendars,
  defaultValue,
  onChange,
  error,
  helperText,
  setRef,
  withoutClearIcon,
  selectedDays,
  onClose,
  ...props
}: DatePickerProps) => {
  const [open, setOpen, popperRef] = useCloseWithOutsideClick();

  const Component = ComponentVariants[variant];
  const rangePickerProps: any =
    variant === 'DateRangePicker'
      ? {
          className: `MuiDateRangePicker-root MuiDateTimePickers ${dataSize}`,
          calendars,
          slots: { textField: Input },
        }
      : {};

  useEffect(() => {
    if (open) return;

    if (onClose) onClose();
  }, [open]);

  const handleOnChange = (e: any, secondprop?: any) => {
    setOpen(false);
    if (!onChange) return;
    if (moment(e).isValid()) return onChange(e, secondprop);

    return onChange(null, secondprop);
  };

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleOnClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleOnChange(null);
  };

  const handleSetRef = (node: any) => {
    if (!node) return;

    if (setRef) setRef(node);
  };

  return (
    <ThemeProvider
      theme={theme(selectedDays?.map((day) => new Date(day.setHours(0, 0, 0, 0)).getTime()))}
    >
      <Component
        value={defaultValue ? defaultValue : null}
        open={open}
        className={`MuiDateTimePickers ${dataSize}`}
        label={label}
        data-size={dataSize}
        dayOfWeekFormatter={(day) => day}
        onChange={handleOnChange}
        slotProps={{
          textField: {
            error: error || !isValidDate(defaultValue, props.minDate, props.maxDate),
            helperText:
              helperText || !isValidDate(defaultValue, props.minDate, props.maxDate)
                ? INPUT_ERRORS.NOT_VALID.DATE
                : '',
            onClick: handleOnClick,
            InputProps: {
              endAdornment: (
                <>
                  <InputAdornment position={'end'} data-date-picker>
                    {!withoutClearIcon && !!defaultValue && (
                      <IconButton onClick={handleOnClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                    <IconButton disabled>
                      <CalendarMonthIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            },
          },
          popper: {
            ref: popperRef,
          },
        }}
        readOnly={!open}
        ref={handleSetRef}
        {...rangePickerProps}
        {...props}
      />
    </ThemeProvider>
  );
};

interface TimePickerProps extends MuiTimePickerProps<any> {
  label: string;
  'data-size'?: 'small' | 'medium' | 'large';
  calendars?: number;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  label,
  'data-size': dataSize = 'large',
  ampm = false,
  ...props
}: TimePickerProps) => {
  return (
    <ThemeProvider theme={theme()}>
      <MuiTimePicker
        className={`MuiDateTimePickers ${dataSize}`}
        label={label}
        data-size={dataSize}
        ampm={ampm}
        {...props}
      />
    </ThemeProvider>
  );
};
