import styled, { css } from 'styled-components';
import { Modal } from './Modal';

export const ModalBase = styled(Modal)`
  & > * {
    display: grid;
    grid-template-rows: 100%;
  }
`;

export const ModalWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-auto-rows: max-content;
  grid-row-gap: 2.8rem;
`;

export const ModalBodyInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--spacing-6);
  grid-auto-rows: max-content;
  grid-row-gap: 2.8rem;
`;

export const ModalBodyCustomColumns = styled(ModalBodyInputs)<{ columns: string }>`
  grid-template-columns: ${(props) => props.columns};
`;

interface ModalFooterButtonsProps {
  columns?: string;
}

export const ModalFooterButtons = styled.div<ModalFooterButtonsProps>`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-3);
  justify-content: end;

  ${(props) =>
    props.columns &&
    css`
      grid-template-columns: ${props.columns};
    `}
`;
