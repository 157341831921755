import { Input, InputBaseProps } from './Input';

const allowedKeys = /[^0-9]/g;

export const InputNumber: React.FC<InputBaseProps> = ({ onInput, ...props }: InputBaseProps) => {
  const handleOnInput = (e: any) => {
    e.target.value = e.target.value.replace(allowedKeys, '');
    const num = e.target.value;
    e.target.value = num.length >= 2 ? num.replace(/^0+/, '') : num;

    if (onInput) onInput(e);
  };

  return <Input onInput={handleOnInput} {...props} />;
};
