import axios from 'axios';
import { GateOutGetAllQuery } from '../../types';

class GateOutsService {
  getAll = (props: any) => axios.get<GateOutGetAllQuery>(`/gate-outs`, props);

  get = (id: any) => axios.get(`/gate-outs/${id}`);

  add = async (props: any) => axios.post(`/gate-outs`, props);

  complete = ({ id, props }: any) => axios.patch(`/gate-outs/${id}`, props);
}

export const gateOutsService = new GateOutsService();
