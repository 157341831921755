import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TableRow, TableCell } from '@mui/material';
import {
  Typography,
  Table,
  TableWrapper,
  TableWrapperFooter,
  TableHeadCell,
  TableIconCell,
  TableDots,
  Pagination,
  Button,
  enqueueSnackbar,
  assignYardsAndClientsModal,
} from '../../../design-system';
import { DashboardHeader } from '../../dashboard';
import { usePaginationState, useClientsQuery, useAssignYardsMutation } from '../../../hooks';
import { formatPhoneNumber } from '../../../utils';
import { TABLE, MESSAGES } from '../../../const';

interface ClientsProps {
  innerScroll?: boolean;
}

export const ClientsPage = styled.div<ClientsProps>`
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 3rem;

  height: 100%;

  ${(props) =>
    props.innerScroll &&
    css`
      overflow: hidden;
    `}
`;

export const ClientsHeader = ({ totalCount }: any) => {
  return (
    <DashboardHeader>
      <Typography variant={'textXL'} weight={'bold'}>
        Clients: {totalCount}
      </Typography>
      <Button size={'medium'} to={'/dashboard/clients/add'}>
        Add Client
      </Button>
    </DashboardHeader>
  );
};

export const ClientsBody = ({ setTotalCount }: any) => {
  const navigate = useNavigate();
  const { page, take, skip, handlePageChange } = usePaginationState();
  const { mutate } = useAssignYardsMutation();
  const [openDotsMenuIndex, setOpenMenuDotsSelect] = useState(null);

  const { data = { data: [], totalCount: 0 }, isFetching } = useClientsQuery({ take, skip });

  useEffect(() => {
    if (isFetching) return;

    setTotalCount(data.totalCount);
  }, [isFetching]);

  const handleDotsToggle = (i: any) => {
    if (openDotsMenuIndex !== i) return setOpenMenuDotsSelect(i);

    setOpenMenuDotsSelect(null);
  };

  const handleDotsOptionSelect = async (action: any, id: any, clientName: string) => {
    if (action !== 'add-yards' && action !== 'reactivate-yards' && action !== 'deactivate-yards')
      return;

    Object.keys(assignYardsAndClientsModal).forEach((key) => {
      if (key !== 'open') delete assignYardsAndClientsModal[key];
    });
    assignYardsAndClientsModal.id = id;
    assignYardsAndClientsModal.assignType = 'yards';
    assignYardsAndClientsModal.action = action;
    assignYardsAndClientsModal.fc = (props: any) =>
      mutate(
        { ...props, clientName, action },
        {
          onSuccess: () => {
            if (action === 'add-yards')
              enqueueSnackbar(MESSAGES.CLIENT.SUCCESS.ASSIGN_YARDS, 'success');
            if (action === 'reactivate-yards')
              enqueueSnackbar(MESSAGES.CLIENT.SUCCESS.REACTIVATED_YARDS, 'success');
            if (action === 'deactivate-yards')
              enqueueSnackbar(MESSAGES.CLIENT.SUCCESS.DEACTIVATED_YARDS, 'success');
          },
          onError: () => {
            if (action === 'add-yards')
              enqueueSnackbar(MESSAGES.CLIENT.ERROR.YARDS_NOT_ASSIGNED, 'error');
            if (action === 'add-yards')
              enqueueSnackbar(MESSAGES.CLIENT.ERROR.YARDS_NOT_REACTIVATED, 'error');
            if (action === 'add-yards')
              enqueueSnackbar(MESSAGES.CLIENT.ERROR.YARDS_NOT_DEACTIVATED, 'error');
          },
        },
      );
    assignYardsAndClientsModal.open();
  };

  const handleRowClick = (to: string) => {
    if (openDotsMenuIndex !== null) return;
    navigate(to);
  };

  return (
    <TableWrapper columns={[7]}>
      <span />
      <Table
        headType={'light'}
        bodyRowSize={'small'}
        showLastRowBorder
        renderTableHeadRow={
          <TableRow>
            <TableHeadCell align={'left'}>Actions</TableHeadCell>
            <TableHeadCell align={'left'}>Client Name</TableHeadCell>
            <TableHeadCell align={'left'}>Email</TableHeadCell>
            <TableHeadCell align={'left'}>Phone Number</TableHeadCell>
            <TableHeadCell align={'left'}>Country</TableHeadCell>
            <TableHeadCell align={'left'}>City</TableHeadCell>
            <TableHeadCell align={'left'}>Code</TableHeadCell>
          </TableRow>
        }
        renderTableBodyRows={data.data.map((row: any, i: any) => (
          <TableRow
            key={'clients' + i}
            onClick={() => handleRowClick(`/dashboard/clients/${row.id}`)}
          >
            <TableIconCell component={'th'} align={'left'}>
              <TableDots
                open={openDotsMenuIndex === i}
                onToggle={() => handleDotsToggle(i)}
                options={[
                  { label: 'Add Yards', value: 'add-yards' },
                  { label: 'Reactivate Yards', value: 'reactivate-yards' },
                  { label: 'Deactivate Yards', value: 'deactivate-yards' },
                ]}
                onSelect={(val: any) => {
                  handleDotsOptionSelect(val, row.id, row.legalName);
                }}
              />
            </TableIconCell>
            <TableCell align={'left'}>{row.legalName}</TableCell>
            <TableCell align={'left'}>{row.email}</TableCell>
            <TableCell align={'left'}>{formatPhoneNumber(row.phoneNumber)}</TableCell>
            <TableCell align={'left'}>{row.address.country}</TableCell>
            <TableCell align={'left'}>{row.address.city}</TableCell>
            <TableCell align={'left'}>{row.code}</TableCell>
          </TableRow>
        ))}
      />
      <TableWrapperFooter>
        <Pagination
          count={Math.ceil(data.totalCount / TABLE.PAGE_MAX_SIZE)}
          page={page}
          onChange={handlePageChange}
        />
      </TableWrapperFooter>
    </TableWrapper>
  );
};
